@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.carousel {
  position: relative;
}

.carouselContainer {
  display: flex;
}

.fadeEffect {
  position: absolute;
  right: 0;
  z-index: 1;
  width: rem(42);
  height: 100%;
  background: $transparent
    linear-gradient(
      270deg,
      $gray-light-7 0%,
      $gray-light-7 8%,
      $gray-medium-9 53%,
      $transparent 100%
    )
    0% 0% no-repeat padding-box;
}

.carouselWrapper {
  overflow: hidden;
  width: 100%;
}

.prevArrow,
.nextArrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  fill: $brown;
}

.prevArrow {
  left: 0;
}

.nextArrow {
  right: 0;
  transform: rotate(180deg);
}

.paginationDots {
  position: absolute;
  bottom: rem(-23);
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: rem(20);

  li {
    width: rem(16);
    height: rem(16);
    border: rem(3) solid $light;
    border-radius: 50%;
    background-color: $gray-light-6;
    cursor: pointer;
  }

  .activeSlide {
    background-color: $red;
  }
}

.customPagination {
  display: flex;

  li {
    flex: 1;
    padding: 0 rem(10);
    border-radius: 0 0 rem(10) rem(10);
    color: $red;
    font-size: 0.8571rem;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.active {
  position: relative;
  padding: 0 rem(10);
  border-radius: 0 0 rem(10) rem(10);
  background: $light;
  box-shadow: 0 rem(5.6) rem(5.8) $gray-shaddow;
}
